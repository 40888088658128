import { FaWhatsapp } from "react-icons/fa";

export function DesktopScheduleAppointment() {
  const srcImage = "/nutri-no-bg/1.png";
  const altImage = "nutri";
  return (
    <div className="hidden relative md:flex justify-end animate-fade-in-right mr-6 ">
      <p className="absolute text-elegant-reds-50 w-[320px] drop-shadow-lg italic text-[1.4rem] bottom-[30rem] right-24  z-10">
        &quot;Alimente seu corpo com escolhas inteligentes, nutrição
        descomplicada é a chave&quot;
      </p>
      <img
        className="absolute drop-shadow-lg  max-w-[130px] right-0 bottom-0 z-10"
        style={{ transform: "rotateY(180deg)" }}
        src={srcImage}
        alt={altImage}
      />
      <section className="bg-elegant-reds-50 p-6 right-0 rounded-lg shadow-md w-[40rem] text-white z-0">
        <p className="max-w-sm text-[2.4rem] mb-10 font-bold">
          Agende sua consulta
        </p>
        <p className="max-w-[20rem] text-[1.2rem] mb-4">
          Escolha uma das opções abaixo para marcar sua consulta
        </p>
        <div className="flex items-center justify-start text-2xl gap-6">
          <div className="overflow-hidden shadow-md p-2  rounded-xl text-white bg-gradient-to-r from-green-700 active:from-green-800 to-green-800 active:to-green-900 w-110">
            <a
              className="flex gap-2 items-center"
              href="https://api.whatsapp.com/send/?phone=5521967862392&text=Ol%C3%A1+Gostaria+de+fazer+um+agendamento+de+consulta+obtive+seu+contato+pelo+Instagram&type=phone_number&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-[1.2rem]">WhatsApp</span>
              <FaWhatsapp className="inline text-[2rem]" />
            </a>
          </div>
          <div className="overflow-hidden  shadow-md p-2 rounded-lg text-elegant-reds-50 bg-white">
            <a
              className="flex gap-2 items-center"
              href="https://www.doctoralia.com.br/marcelle-domingues/nutricionista/nova-iguacu#tab=profile-experience"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-[1.2rem]">Doctoralia</span>
              <img
                src="/icons/doctoralia.png"
                alt="doctoralia-logo"
                className="w-8 h-8"
              ></img>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export function MobileScheduleAppointment() {
  return (
    <div className="flex md:hidden items-end justify-center flex-col w-full gap-4 text-2xl p-10 select-none fixed bottom-8 right-4 z-50">
      <div className="overflow-hidden group rounded-full border shadow-md p-4 group max-w-[52px] hover:max-w-[300px] transition-max-width duration-2000 text-white bg-gradient-to-r from-green-700 active:from-green-800 to-green-800 active:to-green-900">
        <a
          className="flex gap-2 items-center"
          href="https://api.whatsapp.com/send/?phone=5521967862392&text=Ol%C3%A1+Gostaria+de+fazer+um+agendamento+de+consulta+obtive+seu+contato+pelo+Instagram&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="hidden max-w-0 overflow-hidden group-hover:max-w-full group-hover:inline">
            WhatsApp
          </span>
          <FaWhatsapp className="inline text-5xl" />
        </a>
      </div>
      <div className="overflow-hidden rounded-full border shadow-md p-4 group max-w-[52px] hover:max-w-[300px] transition-max-width duration-2000 text-elegant-reds-50 bg-white">
        <a
          className="flex gap-2 items-center"
          href="https://www.doctoralia.com.br/marcelle-domingues/nutricionista/nova-iguacu#tab=profile-experience"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="hidden max-w-0 overflow-hidden group-hover:max-w-full group-hover:inline ">
            Doctoralia
          </span>
          <img
            src="/icons/doctoralia.png"
            alt="doctoralia-logo"
            className="w-10 h-10"
          ></img>
        </a>
      </div>
    </div>
  );
}
