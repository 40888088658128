import FAQSection from "../components/faq-section";
import NavigationBar from "../components/navigation-bar";

export default function FAQ() {
  const bgMainSizeClasses =
    "bg-[length:850px_650px] sm:bg-[length:1050px_750px] md:bg-[length:1150px_850px] lg:bg-[length:1400px_950px]";
  const bgMainPositionClasses =
    " bg-[center_top_12rem] sm:bg-[center_top_8rem] md:bg-[center_top_4rem] lg:bg-top";

  return (
    <main className="min-h-screen w-full bg-opacity overflow-x-hidden">
      <div className="flex justify-center items-center bg-white flex-col">
        <div
          className={`flex flex-col justify-between min-h-[98vh] container bg-main bg-no-repeat bg-clip-border ${bgMainSizeClasses} ${bgMainPositionClasses}`}
        >
          <NavigationBar className="" color="text-elegant-reds-50" />
          <FAQSection />
        </div>
      </div>

      <footer className="w-full bg-zinc-900 text-white align-middle text-center py-2 shadow-lg text-sm">
        <p>2023 © Copyright - Nutricionista Marcelle Domingues</p>
        <p>Todos os direitos reservados</p>
      </footer>
    </main>
  );
}
