import { ReactNode } from "react";

type CardProps = {
  name: string;
  altImage: string;
  srcImage: string;
  children: ReactNode | ReactNode[];
  show: boolean;
};

export default function Cards(props: Readonly<CardProps>) {
  return (
    <article className=" p-4 bg-white border shadow-md w-[40rem] h-[30rem] max-w-full ">
      <section className=" space-x-4 text-xl font-semibold py-4">
        <img
          src={props.srcImage}
          alt={props.altImage}
          className="w-full max-w-16 max-h-16 rounded-full inline-block border-cream-of-roses-300 border-2"
        />
        <span className="text-[1.4rem]">{props.name}</span>
      </section>
      <hr className="border-cream-of-roses-300" />
      <section className=" text-[1.2rem] py-4 text-ellipsis">
        {props.children}
      </section>
    </article>
  );
}
