"use client";
import AskAnswer from "./ask-answer";

const asksAnswers = [
  <AskAnswer
    key="ask-answer-1"
    ask="Como a nutrição pode impactar minha saúde geral?"
  >
    <p>
      Através do consumo de alimentos saudáveis, o nosso corpo recebe os
      nutrientes, as vitaminas e os minerais necessários para manter o
      funcionamento adequado, inclusive atuando na prevenção de doenças DCNT,
      como a obesidade, anemia, diabetes. A nutrição está ligada com a saúde e o
      bem-estar físico e mental.
    </p>
  </AskAnswer>,
  <AskAnswer
    key="ask-answer-2"
    ask="Qual é a importância de uma dieta balanceada?"
  >
    <p>
      Esse é o primeiro passo para ter uma vida longa e de qualidade. Seguir uma
      dieta balanceada, fornece ao corpo o que ele precisa para que ele
      permaneça saudável e desempenhe as suas funções metabólicas e as
      atividades cotidianas de maneira eficaz.
    </p>
    <p>
      Investir numa alimentação saudável pode nos poupar de precisar utilizar
      medicamentos sintéticos no futuro.
    </p>
  </AskAnswer>,
  <AskAnswer
    key="ask-answer-3"
    ask="Como posso perder peso de forma saudável e sustentável?"
  >
    <p>
      Primeiro é preciso estabelecer metas reais para que você consiga seguir,
      sem muita restrição nesse começo, por ser uma adaptação aos novos hábitos,
      uma reeducação alimentar, que vai impactar nas suas escolhas futuras, isso
      é fundamental para um emagrecimento bem-sucedido. Evitar expectativas
      irrealistas e focar apenas na perda de peso, nem tudo é contagem de
      calorias.
    </p>
    <p>
      Defina metas, como aumentar a ingestão de vegetais, frutas, praticar
      exercícios regularmente e diminuir a ingestão de alimentos processados,
      açucarados e refrigerantes.
    </p>
  </AskAnswer>,
  <AskAnswer
    key="ask-answer-4"
    ask="Quais alimentos são recomendados para aumentar minha energia durante o dia?"
  >
    <p>
      Um corpo bem nutrido tem mais energia, os alimentos ingeridos podem ser
      metabolizados e, finalmente, convertidos em energia.
    </p>
    <p>
      Por outro lado, os doces, frituras, carboidratos simples (como pães
      brancos), produtos ultraprocessados e bebidas alcoólicas, devem ser
      evitados pois prejudicam e interferem na nossa disposição.
    </p>
    <p>
      Alguns alimentos que contribuem, podemos citar a banana, aveia, iogurte,
      as oleaginosas, chocolate amargo, peixe, ovo, água de coco, folhas verdes
      escuras, gengibre, açafrão, chá verde, esses são alguns alimentos que
      contribui, porém não podemos deixar de lembrar da importância da
      hidratação e do sono.
    </p>
  </AskAnswer>,
  <AskAnswer
    key="ask-answer-5"
    ask="Como a nutrição pode impactar minha performance esportiva?"
  >
    <p>
      Uma alimentação inadequada pode comprometer a recuperação muscular,
      retardar a reparação de tecidos danificados e aumentar o tempo de
      recuperação entre as sessões de treinamento. Isso pode limitar o progresso
      e levar a um desempenho estagnado.
    </p>
  </AskAnswer>,
  <AskAnswer
    key="ask-answer-6"
    ask=" É possível seguir uma dieta vegetariana ou vegana de maneira saudável?"
  >
    <p>
      Na dieta vegetariana e na vegana, a alimentação é basicamente de frutas,
      folhas, legumes, cereais, leguminosas, nozes e sementes.
    </p>
    <p>
      O único nutriente ausente na dieta vegana e vegetariana, é a vitamina B12,
      que deve ser consumida na forma de suplemento.
    </p>
    <p>
      O que vai definir se será uma alimentação saudável ou não está na forma em
      que a dieta será baseada: em alimentos naturais (ricos em fibras,
      antioxidantes, fitoquímicos, aminoácidos e gorduras boas) ou em produtos
      industrializados (ricos em açúcar, farinha, gorduras trans e corantes).
    </p>
  </AskAnswer>,
  <AskAnswer
    key="ask-answer-7"
    ask=" Como a nutrição pode ajudar na prevenção de doenças crônicas?"
  >
    <p>
      Para que a alimentação seja eficiente na prevenção de doenças, precisamos
      consumir proteínas, vitaminas, carboidratos, lipídios, fibras e minerais,
      além de ingestão suficiente de água. É importante ter em mente que alguns
      grupos alimentares são ricos em certos nutrientes e, muitas vezes, pobres
      em outros.
    </p>
    <p>
      Por isso o ideal é procura ajuda de um profissional qualificado, pois ele
      garantirá a quantidade necessária para cada ocasião.
    </p>
  </AskAnswer>,
  <AskAnswer
    key="ask-answer-8"
    ask="Qual é o papel da nutrição na saúde mental?"
  >
    <p>
      Ter uma alimentação saudável, melhora a qualidade do sono, melhora o
      humor, a auto-estima, reduz níveis de estresse e sintomas depressivos.
    </p>
    <p>
      Devido à influência da conexão entre alimentação e saúde mental, há muitos
      estudos que associam a nutrição aos aspectos emocionais.
    </p>
  </AskAnswer>,
  <AskAnswer
    key="ask-answer-9"
    ask="Como posso controlar a compulsão alimentar e os hábitos alimentares emocionais?"
  >
    <p>
      O gasto de energia é uma forma de controlar a fome emocional. Existem
      muitos sentimentos e cobranças que podem levar a uma compulsão alimentar,
      identificar os gatilhos que despertam a fome emocional é a primeira
      condição para que possamos lidar com ela. Uma rotina de autocuidado ajuda
      a equilibrar a balança.
    </p>
    <p>
      Para reduzir o risco de comer emocionalmente, é bom limitar o acesso em
      guloseimas como salgadinhos, doces e alimentos industrializados, e
      priorizar frutas e fontes de fibras.
    </p>
    <p>
      Aumentar a ingestão de proteínas pode ajudar na saciedade e a controlar o
      apetite.
    </p>
  </AskAnswer>,
  <AskAnswer
    key="ask-answer-10"
    ask="Quais são os benefícios de consultar um nutricionista?"
  >
    <p>
      A nutrição adequada contribui para uma melhora da saúde mental e física,
      além de fornecer energia e promover o bem-estar geral.
    </p>
    <p>
      A nutrição ajuda a evitar o desenvolvimento e manter o controle de doenças
      crônicas, como diabetes e hipertensão, por meio de um estilo de vida mais
      saudável. Determinar a quantidade ideal de nutrientes que cada um precisa
      ingerir, pois a nutrição é individual.
    </p>
    <p>
      O nutricionista não atua somente na ajuda em perder peso ou por questão
      estética, os benefícios de uma consulta com nutricionista vai muito além
      disso.
    </p>
  </AskAnswer>,
];

export default function FAQSection() {
  return (
    <div className="container min-h-semi-screen flex flex-col text-elegant-reds-50 relative bg-white bg-opacity-70 z-20">
      <div
        id="faq"
        className="mt-20 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 max-w-max mx-auto px-10 pb-10 "
      >
        {asksAnswers}
      </div>
    </div>
  );
}
