export default function WhoIamSection() {
  const srcNutriImage = "/nutri/7.jpeg";
  const altNutriImage = "marcelle_quem_sou_eu";
  return (
    <div
      id="quemsoueu"
      className="min-h-screen flex flex-col px-10 pb-10 z-20 "
    >
      <div className="mt-20 md:mt-40 w-full flex gap-12 items-center justify-start p-2 flex-row">
        <div className="relative border-4 w-[16rem] h-[16rem] md:w-[20rem] md:h-[20rem] lg:w-[30rem] lg:h-[30rem] rounded-full overflow-hidden border-cream-of-roses-300 mb-4  md:mb-0 shrink-0 ">
          <img
            src={srcNutriImage}
            alt={altNutriImage}
            className="absolute w-auto h-auto -top-10 "
          />
        </div>
        <p className="max-w-[36rem] sm:min-w-[30rem] text-[1.2rem] sm:text-[2rem] italic font-sans md:mb-0 shrink">
          &quot;A nutrição não é apenas sobre o que você come, mas como você
          vive. Vamos criar um estilo de vida que ressoa com sua saúde.&quot;
        </p>
      </div>
      <section className="max-w-6xl text-[1.4rem] p-2 mt-6 ">
        <p className="mb-3">
          Meu nome é Marcelle Domingues, membro da ASBRAN(Associação Brasileira
          de Nutrição) e da SBEMOE(Sociedade Brasileira de Medicina da
          Obesidade) e estou entusiasmada em compartilhar minha paixão pela
          nutrição e bem-estar com você. Cada etapa da minha jornada acadêmica e
          profissional moldou minha abordagem única para transformar vidas por
          meio da alimentação consciente e equilibrada.
        </p>
        <p className="mb-3">
          Como nutricionista autônoma acumulei muita experiência a cada
          paciente. Minhas competências abrangem desde nutrição clínica e
          dietética até educação alimentar e análise nutricional.
        </p>
        <p className="mb-3">
          Acredito na importância de uma abordagem holística para a saúde, e é
          por isso que me empenho em fornecer orientação personalizada a cada
          cliente. Não sou apenas uma nutricionista; sou uma parceira
          comprometida em guiá-lo rumo a escolhas alimentares mais conscientes e
          a um estilo de vida mais saudável.
        </p>
        <p className="mb-5">
          Juntos, podemos explorar como a nutrição pode ser uma jornada
          inspiradora e transformadora. Estou aqui para ajudar você a atingir
          seus objetivos de saúde e bem-estar. Vamos começar essa jornada única
          rumo a uma vida mais saudável e plena!
        </p>
        <p className="font-semibold">CRN-4/ 22102549/P</p>
      </section>
    </div>
  );
}
