import { useState } from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

type PhotoProps = {
  url: string;
  show: boolean;
  scale?: number;
  x?: number;
};

const Clinic = {
  Address: () => (
    <>
      <p>Funcionamento: 09h - 18h</p>
      <p>Endereço: Rua Ministro Lafaiete de Andrade, 1041. Sobreloja</p>
      <p>CEP: 26261-220</p>
    </>
  ),
  EmailForm: () => (
    <>
      <p className="mt-20 mb-2 text-4xl">Cadastre seu contato</p>
      <form action="" className=" space-y-6 bg-slate-400 py-5 sm:px-10 px-5">
        <fieldset className="flex flex-col flex-auto">
          <label htmlFor="input-email">E-mail</label>
          <input
            type="text"
            id="input-email"
            name="input-email"
            className="focus:outline-none text-4xl w-full"
          />
        </fieldset>
        <fieldset className="flex flex-col flex-auto">
          <label htmlFor="input-name">Nome</label>
          <input
            type="text"
            id="input-name"
            name="input-name"
            className="focus:outline-none text-4xl w-full"
          />
        </fieldset>
        <fieldset className="flex flex-col flex-auto">
          <input
            type="submit"
            value="Enviar"
            className="border cursor-pointer"
          />
        </fieldset>
      </form>
    </>
  ),
  GoogleMap: () => (
    <iframe
      title="clinic-location"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.2989522925964!2d-43.485058023891895!3d-22.754284232440128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9967a9162833c5%3A0xfa1f1183335dd1a0!2sNutricionista%20Marcelle%20Domingues!5e0!3m2!1spt-BR!2sbr!4v1700770067394!5m2!1spt-BR!2sbr"
      className="border shadow-lg w-[90vw] sm:w-[60rem] h-[20rem] md:w-[30rem] md:h-[30rem] lg:w-[40rem] lg:h-[40rem]"
      allowFullScreen={false}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  ),
  Photo: ({ url, show, scale = 1, x = 0 }: PhotoProps) => {
    const transform = `translateX(${x}px) scaleY(${scale})`;
    const isShowClasses = show
      ? "flex-[5]"
      : `hidden md:block flex-[1] brightness-[0.40] `;

    return (
      <div
        style={{
          backgroundImage: `url('${url}')`,
          transform,
        }}
        className={`bg-center bg-[length:auto_auto] bg-no-repeatw-auto w-full md:w-[800px] h-[459px] shadow-sm ${isShowClasses}`}
      ></div>
    );
  },
};

export default function Component() {
  const [urls, setUrls] = useState<Array<string>>([
    "/consultorio/6.jpg",
    "/consultorio/7.jpg",
    "/consultorio/5.jpg",
    "/consultorio/8.jpg",
    "/consultorio/9.jpg",
  ]);

  const handlePrevious = () => {
    let newUrls = urls;
    const last = newUrls.pop() as string;
    newUrls = [last].concat(...newUrls);

    setUrls(newUrls);
  };

  const handleNext = () => {
    let newUrls = urls;
    const first = newUrls.shift() as string;
    newUrls = newUrls.concat(first);

    setUrls(newUrls);
  };

  const renderPhotos = () => {
    return urls.map((u, i) => {
      const key = u.replace(".jpg", "").replace("/", "-");
      let show = false;
      let scale = 0.8;

      if (i + 1 === Math.ceil(urls.length / 2)) {
        show = true;
        scale = 1;
      } else {
        if (u !== urls[0] && u !== urls[urls.length - 1]) {
          scale = 0.9;
        }
      }
      return <Clinic.Photo key={key} show={show} url={u} scale={scale} />;
    });
  };

  return (
    <div className=" flex flex-col w-full  mt-[20rem] mb-10 z-20 text-white">
      <div
        id="consultorio"
        className="flex items-center flex-col gap-[20rem] translate pt-20 "
      >
        <div className="w-full max-w-[80vw] sm:max-w-[90vw] h-[60vh] flex justify-center items-center relative">
          {renderPhotos()}
          <button
            className=" bg-black absolute -left-8 lg:-left-12 w-[4rem] h-[4rem] lg:w-[6rem] lg:h-[6rem] flex justify-center items-center rounded-full"
            onClick={handlePrevious}
          >
            <MdArrowBackIos className="my-auto text-white text-[1.6rem]" />
          </button>
          <button
            className=" bg-black absolute -right-8 lg:-right-12 w-[4rem] h-[4rem] lg:w-[6rem] lg:h-[6rem] flex justify-center items-center rounded-full"
            onClick={handleNext}
          >
            <MdArrowForwardIos className="my-auto text-white text-[1.6rem]" />
          </button>
        </div>

        <section className="w-full flex justify-evenly items-start text-xl flex-wrap space-y-28 md:space-y-0">
          <div className="max-w-full md:mt-[10rem]">
            <Clinic.Address />
          </div>
          <Clinic.GoogleMap />
        </section>
      </div>
    </div>
  );
}
