"use client";
import { ReactNode } from "react";

type AskAnswerProps = {
  ask: string;
  children: ReactNode | ReactNode[];
};

export default function AskAnswer(props: Readonly<AskAnswerProps>) {
  return (
    <article className=" group overflow-hidden w-full ">
      <section className="w-full space-x-4 text-xl font-semibold px-4 py-2 border-slate-100  overflow-hidden">
        <span>{props.ask}</span>
      </section>
      <section className="text-xl w-full overflow-hidden ">
        <div className="mx-4 my-2">{props.children}</div>
      </section>
    </article>
  );
}
