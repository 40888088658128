import { FaBars } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useCallback, useState } from "react";

export default function NavigationBar({
  className,
  color,
}: {
  className: string;
  color?: string;
}) {
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = useCallback(() => {
    if (!openMenu) {
      setOpenMenu(true);
    }
  }, [openMenu]);

  const handleCloseMenu = useCallback(() => {
    if (openMenu) {
      setOpenMenu(false);
    }
  }, [openMenu]);

  return (
    <nav
      className={`flex gap-24 flex-shrink justify-end sm:justify-center items-center text-center px-[2rem] text-[1.2rem] mt-10 tracking-widest ${
        color ? color : "text-white"
      } ${className}`}
    >
      <button className="sm:hidden" onClick={handleOpenMenu}>
        <FaBars className="text-[2.5rem] font-expanded" />
      </button>
      <div
        className={`sm:hidden ${
          openMenu ? "flex" : "hidden"
        }  justify-end fixed left-0 top-0 h-[100vh] w-full bg-black bg-opacity-50 z-[999]`}
      >
        <div className="bg-white w-[30rem] h-full flex flex-col justify-start items-center gap-10 text-[#660032]">
          <button
            className="p-5 mt-5 mb-[4rem] self-end"
            onClick={handleCloseMenu}
          >
            <AiOutlineClose className="text-[3rem]" />
          </button>

          <Links coletiveClassName="sm:hidden inline" />
        </div>
      </div>
      <Links coletiveClassName="hidden sm:inline" />
    </nav>
  );
}

function Links(props: { coletiveClassName: string }) {
  return (
    <>
      <span
        className={`${props.coletiveClassName} hover:underline hover:text-[#CC9C9A]`}
      >
        <a href="/#quemsoueu">Quem sou eu</a>
      </span>
      <span
        className={`${props.coletiveClassName} hover:underline hover:text-[#CC9C9A]`}
      >
        <a href="/#avaliacoes">Avaliações</a>
      </span>
      <span
        className={`${props.coletiveClassName} hover:underline hover:text-[#CC9C9A]`}
      >
        <a href="/#consultorio">Consultório</a>
      </span>
      <span
        className={`${props.coletiveClassName} hover:underline hover:text-[#CC9C9A]`}
      >
        <Link to={"/faq"}>FAQ</Link>
      </span>
      <span
        className={`${props.coletiveClassName} hover:underline hover:text-[#CC9C9A]`}
      >
        <Link
          to={
            "https://pay.hotmart.com/L89653867Y?checkoutMode=10&bid=1708368511283"
          }
        >
          E-books
        </Link>
      </span>
    </>
  );
}
