import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./home-page";
import FAQ from "./faq-page";

const router = createBrowserRouter([
  { index: true, path: "/", element: <Home /> },
  { index: true, path: "/faq", element: <FAQ /> },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
