import NavigationBar from "../components/navigation-bar";
import {
  DesktopScheduleAppointment,
  MobileScheduleAppointment,
} from "../components/schedule-appointment";
import WhoIamSection from "../components/who-i-am-section";
import EvaluationSection from "../components/evaluation-section";
import ClinicSection from "../components/clinic-section";

export default function Home() {
  return (
    <main className="w-full bg-opacity">
      <div className="bg-gradient-elegant-reds w-full h-screen">
        <div className="max-w-full w-full h-full flex flex-col justify-between bg-main bg-no-repeat bg-clip-border bg-contain bg-center sm:bg-[length:600px_420px] sm:bg-[center_top_12rem] overflow-x-hidden">
          <NavigationBar className="" />
          <DesktopScheduleAppointment />
          <MobileScheduleAppointment />
        </div>
      </div>
      <div className="flex justify-center items-center flex-wrap bg-white text-elegant-reds-50 relative min-h-screen">
        <div className="flex-[2]">
          <WhoIamSection />
        </div>
        <div className=" flex-[1] m-10 sm:m-0 bg-shape bg-no-repeat bg-origin-border bg-clip-border bg-center bg-contain h-[40rem] min-w-[25rem] sm:min-w-[40rem]"></div>
      </div>
      <div className="flex flex-col justify-center items-center bg-white py-10">
        <EvaluationSection />
      </div>
      <div className="flex justify-center items-center min-h-screen bg-gradient-e-reds-white">
        <ClinicSection />
      </div>

      <footer className="w-full bg-[#222] py-4 ">
        <p className="text-white align-middle text-center  italic text-[1.4rem]">
          2024 © Copyright - Nutricionista Marcelle Domingues
        </p>
        <p className="text-white align-middle text-center text-[1.2rem]">
          Todos os direitos reservados
        </p>
      </footer>
    </main>
  );
}
