"use client";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "../components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import Card from "./card";
import { useCallback, useEffect, useState } from "react";

const comments = [
  <Card
    key="commentcard-1"
    name="Gleice Guimarães"
    srcImage="/pacientes/gleice-guimaraes.jpeg"
    altImage="paciente_1_img"
    show={true}
  >
    <p>
      &quot;Hoje iniciei um novo e super importante passo nesse processo de
      viver com mais qualidade &#127822;
    </p>
    <p>
      Meu primeiro atendimento NUTRICIONAL com a @nutrimarcelledomingues e foi
      sensacional!! Gente, sem palavras para essa mulher! Muito profissional,
      inteligente, carismática e atualizada! Foi sem dúvidas a MELHOR consulta
      nutricional que eu já tive!...&quot;
    </p>
  </Card>,
  <Card
    key="card-2"
    name="Pammela Sena"
    srcImage="/pacientes/pammela-sena.jpeg"
    altImage="paciente_2_img"
    show={true}
  >
    <p>
      &quot;Em 1 mês já consegui perder 3kg, isso ainda sem ir para academia 🙌
      só com reeducação alimentar e quero dizer que devo muito isso a você! Seu
      conhecimento, dicas e o principal que é o apoio 🌹
    </p>
    <p>
      Foi fundamental para essa &quot;virada de chave&quot; na minha vida.
      Precisava fazer pela saúde e claro pela autoestima. Obrigada por tudo!
      😘&quot;
    </p>
  </Card>,
  <Card
    key="card-3"
    name="Nathália Bernardo"
    srcImage="/pacientes/nathalia-bernardo.jpeg"
    altImage="paciente_3_img"
    show={true}
  >
    <p>&quot;Nossa amei a consulta 😍</p>
    <p>
      Sai muito motivada, as vezes precisamos mudar e ter segundas opiniões,
      novos olhares. Fiquei chocada com minha avaliação...&quot;
    </p>
  </Card>,
  <Card
    key="card-4"
    name="Xainna Fortuna"
    srcImage="/pacientes/xainna-fortuna.jpeg"
    altImage="paciente_4_img"
    show={true}
  >
    <p>
      &quot;Logo na minha primeira consulta, fiquei surpreendida com o
      conhecimento da Marcelle. Ao mesmo tempo que é uma profissional bastante
      segura nas informações, é extremamente acolhedora.{" "}
    </p>
    <p>
      Perdi peso, melhorei significadamente a minha alimentação. Muito obrigada
      por seu impecável atendimento!...&quot;
    </p>
  </Card>,
  <Card
    key="card-5"
    name="Jady Viana"
    srcImage="/pacientes/jady-viana.jpeg"
    altImage="paciente_5_img"
    show={true}
  >
    <p>
      &quot;Gostaria de enaltecer o trabalho da Marcelle essa nutri maravilhosa
      que tive a oportunidade de conhecer, estou há 4 meses sob o acompanhamento
      dela e simplesmente a cada mês que passa fico impressionada com meu
      resultado. Vale muito a pena.
    </p>
    <p>
      E temos que ressaltar que na dieta dela tem doce de leiteeeee 🤣🤣 eu
      amo&quot;
    </p>
  </Card>,
  <Card
    key="card-6"
    name="Pedro Nascimento"
    srcImage="/pacientes/pedro-nascimento.jpeg"
    altImage="paciente_6_img"
    show={true}
  >
    <p>
      &quot;Há 1 ano atrás, com 12 anos comecei o acompanhamento pois estava com
      pré-diabetes e pré-obesidade infantil. Hoje isso tudo ficou pra trás, no
      ínicio foi complicado pois tive que fazer algumas restrições, mas descobri
      que essas restrições não me fazem falta, descobri com você que uma criança
      pode sim e deve mudar seus hábitos alimentares.
    </p>
    <p>
      Meu corpo mudou por dentro e por fora e daqui pra frente só vai melhorar,
      pois esse hábito alimentar é pra vida toda.&quot;
    </p>
  </Card>,
  <Card
    key="card-7"
    name="Cynthia Coutinho"
    srcImage="/pacientes/cynthia-coutinho.jpeg"
    altImage="paciente_7_img"
    show={true}
  >
    <p>
      &quot;Em Outubro fez 1 ano que comecei com o acompanhamento nutricional.
      Só tenho que te agradecer, não digo só pela beleza porque isso vem como
      bônus, mas pela qualidade de vida.
    </p>
    <p>
      Comecei pela busca por um motivo e vi resultado em um todo. Umas das
      coisas que me chamaram mais a atenção foi a duração da consulta, as
      perguntas que você faz, você explica e ensina.
    </p>
    <p>
      Não faço dieta, tenho uma alimentação saudável. Isso se chama qualidade de
      vida&quot;
    </p>
  </Card>,
  <Card
    key="card-8"
    name="Fernanda Santos"
    srcImage="/pacientes/fernanda-santos.jpeg"
    altImage="paciente_8_img"
    show={true}
  >
    <p>
      &quot;A consulta com a Marcelle é tão maravilhosa que só quem já passou
      por uma sabe.
    </p>
    <p>
      Sempre tão solicita, tem propriedade pra falar sobre qualquer assunto e
      sanar todas nossas dúvidas. Me senti a vontade para falar sobre tudo,
      principalmente porque ela busca conhecer e colher muitas informações sobre
      a gente.
    </p>
    <p>
      Agradeço por todo conhecimento que ela me passou e pela motivação de mudar
      minha vida e meus hábitos.&quot;
    </p>
  </Card>,
  // <Card
  //   key="card-9"
  //   name="Nathaly Gonçalves"
  //   srcImage="/pacientes/nathaly-goncalves.jpeg"
  //   altImage="paciente_9_img"
  // >
  //   <p>
  //     &quot;A Marcelle tem sido minha nutricionista há pouco tempo, mas desde a
  //     primeira consulta já percebi a alta qualidade de seu trabalho, com seu
  //     conhecimento da parte nutricional e também na solicitação da profunda
  //     anamnese para avaliar nossa saúde como um todo. Tive uma grande melhora na
  //     disposição, pelo, peso, massa magra e também no meu preparo para longos
  //     treinos.{" "}
  //   </p>
  //   <p>
  //     O melhor de tudo é que consegui manter a rotina porque o plano alimentar é
  //     adaptado ao nosso dia a dia e gostos alimentares. Não moro perto de seu
  //     consultório, contudo com a opção de fazer consultas online, fiquei feliz
  //     de ter esse acompanhamento e ainda mais feliz com os resultados de perda
  //     de peso em tão pouco tempo.{" "}
  //   </p>
  //   <p>
  //     {" "}
  //     Vários hábitos saudáveis que aprendi para manter minha qualidade de vida,
  //     que me acompanhará durante anos com a MELHOR.{" "}
  //   </p>
  //   <p>
  //     Enfim, sou sua fã e super te recomendo a todos que buscam uma vida
  //     saudável e descomplicada.{" "}
  //   </p>
  //   <p>Você é incrivel e uma profissional sem tamanho, parabéns&quot;</p>
  // </Card>,
  // <Card
  //   key="card-10"
  //   name="Luana Barbosa"
  //   srcImage="/pacientes/luana-barbosa.jpeg"
  //   altImage="paciente_10_img"
  // >
  //   <p>&quot; Boa tarde, Marcella!! </p>
  //   <p>
  //     Passando só pra te atualizar da minha alimentação e agradecer porque em
  //     UMA SEMANA eu já senti uma diferença absurda!!! Eu nunca consegui fazer
  //     nenhuma dieta, sempre foi difícil pra mim, mas dessa vez estou conseguindo
  //     manter uma rotina fixa e incluir tudo que você passou. Não está sendo
  //     difícil, pelo contrário, ta sendo ótimo! To muito feliz!
  //   </p>
  //   <p> To te indicando para todo mundo! ❤️❤️</p>
  //   <p>
  //     Eu nunca tinha cogitado a possibilidade de eu ter algum tipo de
  //     intolerância a certos alimentos e essa semana sem a lactose e sem farinha
  //     me deixaram muito bem, sem inchaços, me sinto mais ativa, mais disposta!
  //   </p>
  //   <p>Obrigada por essa atenção!&quot;</p>
  // </Card>,
  <Card
    key="card-11"
    name="Mariane Sampaio"
    srcImage="/pacientes/mariane-sampaio.jpeg"
    altImage="paciente_11_img"
    show={true}
  >
    <p>
      &quot;Quando pensei em passar por uma nutricionista a pedido médico,
      fiquei já estressada pensando que ia passar fome, mas a Marcelle foi
      incrível durante todo o processo, sempre fazendo uma alimentação saudável
      só para meu estilo de vida, me acompanhou em cada momento e sempre com
      dicas rápidas e direitas me ensinando a comer bem, ou seja, me reeducando
      para não ter efeito sanfona.
    </p>
    <p>
      Recomendo demais para quem está buscando uma profissional de excelência e
      que não quer deixar de comer.&quot;
    </p>
  </Card>,
];

export default function EvaluationSection() {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  const handleClickButton = useCallback(
    (n: number) => {
      if (api && current !== n) {
        setCurrent(n);
        api?.scrollTo(n - 1);
      }
    },
    [api, current]
  );

  return (
    <>
      <div
        id="avaliacoes"
        className="flex justify-center items-center container text-elegant-reds-50  z-20 mb-14 "
      >
        <Carousel
          setApi={setApi}
          opts={{
            align: "start",
            duration: 100,
          }}
          plugins={[
            Autoplay({
              delay: 5000,
            }),
          ]}
          className=" max-w-full md:max-w-[42rem] lg:max-w-[84rem] xl:max-w-[126rem]"
        >
          <CarouselContent>
            <CarouselItem className=" lg:basis-1/2 xl:basis-1/3 py-2 mx-auto max-w-full">
              {comments[0]}
            </CarouselItem>
            <CarouselItem className=" lg:basis-1/2 xl:basis-1/3 py-2 mx-auto max-w-full">
              {comments[1]}
            </CarouselItem>
            <CarouselItem className=" lg:basis-1/2 xl:basis-1/3 py-2 mx-auto max-w-full">
              {comments[2]}
            </CarouselItem>
            <CarouselItem className=" lg:basis-1/2 xl:basis-1/3 py-2 mx-auto max-w-full">
              {comments[3]}
            </CarouselItem>
            <CarouselItem className=" lg:basis-1/2 xl:basis-1/3 py-2 mx-auto max-w-full">
              {comments[4]}
            </CarouselItem>
            <CarouselItem className=" lg:basis-1/2 xl:basis-1/3 py-2 mx-auto max-w-full">
              {comments[5]}
            </CarouselItem>
            <CarouselItem className=" lg:basis-1/2 xl:basis-1/3 py-2 mx-auto max-w-full">
              {comments[6]}
            </CarouselItem>
            <CarouselItem className=" lg:basis-1/2 xl:basis-1/3 py-2 mx-auto max-w-full">
              {comments[7]}
            </CarouselItem>
            <CarouselItem className=" lg:basis-1/2 xl:basis-1/3 py-2 mx-auto max-w-full">
              {comments[8]}
            </CarouselItem>
          </CarouselContent>
        </Carousel>
      </div>
      <div className="flex gap-2 justify-center items-center">
        {Array.from({ length: count }).map((_, index) => (
          <button
            key={`evaluation-button-${index + 1}`}
            onClick={() => handleClickButton(index + 1)}
            className={`w-[1.6rem] h-[1.6rem] rounded-full shadow-md ${
              current === index + 1 ? "bg-[#f6afaf]" : "bg-slate-200"
            }`}
          ></button>
        ))}
      </div>
    </>
  );
}
